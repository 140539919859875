import React, { Fragment } from 'react';
import styled from 'styled-components';

import { aboutHeroTypes } from '../types/propTypes';
import mq from '../style/mq';
import IconSrc from '../images/category-hero.svg';
import { Container, InnerSmall } from './Elements';
import Cat from '../images/cat.svg';
import { font } from '../consts/style';
const CatIcon = styled.img.attrs({ src: Cat, alt: 'Cat icon' })``;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  padding-top: 6rem;
  margin-bottom: 12rem;

  ${InnerSmall} {
    z-index: 1;
    position: relative;
  }

  .image {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 46rem;
    height: 38rem;
    transform: translateY(40%) translateX(-40%);
    z-index: 0;
    img {
      width: 100%;
    }
  }
  .icon {
    text-align: center;
    img {
      width: 6.6rem;
    }
  }
  .content {
    text-align: center;
    h1,
    h2,
    .info {
      margin-top: 2.4rem;
    }
    h1 {
      ${font.h1cat};
    }
    h2 {
      ${font.displaySmallTitle};
    }
  }

  ${mq.tabletSmall`
    .image { 
      width: 23rem;
      height: 19rem;
    }
  `}
`;
const Icon = styled.img.attrs({ src: IconSrc })``;

export default function AboutHero({ title, smallTitle, introText }) {
  return (
    <Fragment>
      <Wrapper>
        <div className="image">
          <Icon />
        </div>
        <Container>
          <InnerSmall>
            <div className="icon">
              <CatIcon />
            </div>
            <div className="content">
              <h1>{title}</h1>
              <h2>{smallTitle}</h2>
              <div
                className="info"
                dangerouslySetInnerHTML={{ __html: introText }}
              />
            </div>
          </InnerSmall>
        </Container>
      </Wrapper>
    </Fragment>
  );
}

AboutHero.propTypes = aboutHeroTypes;
