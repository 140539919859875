import React, { Fragment } from 'react';
import styled from 'styled-components';
import map from 'lodash/map';

// import SectionHeading from './SectionHeading';
import { aboutWhatBlockTypes } from '../types/propTypes';
import { Container, Inner } from './Elements';
import { shapeGroupImages } from '../consts/data';
import { font } from '../consts/style';
import mq from '../style/mq';

const Wrapper = styled.div`
  overflow-x: hidden;
`;
const Block = styled.div`
  margin-top: 4rem;
  display: flex;
  align-items: center;
  .img,
  .content {
    width: 50%;

    img {
      max-width: 100%;
    }

    h3 {
      ${font.h2};
      text-transform: none;
      margin-bottom: 2.4rem;
    }

    p {
      ${font.p};
    }
  }
  .img {
    text-align: center;
  }

  &:nth-child(odd) {
    .img {
      order: 1;
    }
    .content {
      order: 0;
    }
  }

  ${mq.tabletSmall`
    margin-bottom: 8rem;
    flex-wrap: wrap;
    .img, .content {
      width: 100%;

      img {
        width: 50%;
        margin: auto;
        margin-bottom: 4rem;
      }
    }
    &:nth-child(odd) {
    .img {
      order: 0;
    }
    .content {
      order: 1;
    }
  }
  `}
`;

export default function AboutWhat({ whatBlocks }) {
  function renderBlocks() {
    return map(whatBlocks, (wb, i) => {
      let imageIndex = i;
      if (i > shapeGroupImages.length) {
        imageIndex =
          i - shapeGroupImages.length * Math.floor(i / shapeGroupImages.length);
      }
      return (
        <Block key={wb.id}>
          <div className="img">
            <img src={shapeGroupImages[imageIndex]} alt={`Shape ${i + 1}`} />
          </div>
          <div className="content">
            <h3>{wb.title}</h3>
            <div
              className="title"
              dangerouslySetInnerHTML={{ __html: wb.content }}
            />
          </div>
        </Block>
      );
    });
  }
  return (
    <Fragment>
      <Wrapper id="what">
        <Container>
          <Inner>{renderBlocks()}</Inner>
        </Container>
      </Wrapper>
    </Fragment>
  );
}

AboutWhat.propTypes = aboutWhatBlockTypes;
