import image1 from '../images/shape-group-1.svg';
import image2 from '../images/shape-group-2.svg';
import image3 from '../images/shape-group-3.svg';
import image4 from '../images/shape-group-4.svg';
import image5 from '../images/shape-group-5.svg';

export const shapeGroupImages = [
  image2,
  image5,
  image2,
  image5,
  image2,
  image5,
  image2,
  image5,
  image2,
  image5,
  image2,
  image5,
];
