import React, { Fragment } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import SEO from '../components/SEO';
// import AboutBlocks from '../components/AboutBlocks';
import AboutWhat from '../components/AboutWhat';
// import AboutHow from '../components/AboutHow';
// import AboutWhy from '../components/AboutWhy';
import AboutWho from '../components/AboutWho';
// import Contact from '../components/Contact';
import AboutHero from '../components/AboutHero';

const aboutQuery = graphql`
  {
    datoCmsAbout {
      heroTitle
      heroSubtitle
      heroCopy
      # aboutBlocks {
      #   id
      #   text
      # }
      whatBlocks {
        id
        title
        content
      }
      # howBlocks {
      #   id
      #   heading
      #   subheading
      #   text
      # }
      # whyBlocks {
      #   id
      #   title
      #   content
      # }
      team {
        id
        name
        title
        image {
          fluid(maxWidth: 600, imgixParams: { fm: "jpg", auto: "compress" }) {
            ...GatsbyDatoCmsFluid_noBase64
          }
        }
        bio
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`;

export default function About() {
  const data = useStaticQuery(aboutQuery);
  const {
    heroTitle,
    heroCopy,
    heroSubtitle,
    seoMetaTags,
    // aboutBlocks,
    whatBlocks,
    // howBlocks,
    // whyBlocks,
    team,
  } = data.datoCmsAbout;

  const aboutHeroProps = {
    title: heroTitle,
    smallTitle: heroSubtitle,
    introText: heroCopy,
  };

  // const aboutBlocksProps = {
  //   aboutBlocks,
  // };

  const AboutWhatProps = {
    whatBlocks,
  };

  // const howBlockProps = {
  //   howBlocks,
  // };
  // const whyBlockProps = {
  //   whyBlocks,
  // };

  const whoBlockProps = {
    title: 'The cats behind the content',
    team,
  };

  return (
    <Fragment>
      <SEO meta={seoMetaTags} />
      <AboutHero {...aboutHeroProps} />
      {/* <AboutBlocks {...aboutBlocksProps} /> */}
      <AboutWhat {...AboutWhatProps} />
      {/* <AboutHow {...howBlockProps} /> */}
      {/* <AboutWhy {...whyBlockProps} /> */}
      <div style={{ paddingTop: '40px' }}>
        <AboutWho {...whoBlockProps} />
      </div>
      {/* <Contact /> */}
    </Fragment>
  );
}
